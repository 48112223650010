@charset 'UTF-8';

// Font family
//$font-family: 'YakuHanJPs', -apple-system  BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', '游ゴシック体', 'Yu Gothic', YuGothic, 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, arial, sans-serif;
//$font-family: 'YakuHanJPs', Helvetica, Arial, '游ゴシック体', 'Yu Gothic', YuGothic,  'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
$font-family: 'YakuHanJPs', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Hiragino Sans", "Noto Sans CJK JP", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Sans Emoji";



// body の text color
$body-text-color: #333 !default;

// body の background-color
$background-body: #f9f8eb !default;

// ベースの文字サイズ（指定：px）
$base-font-size: (
  sm: 13,
  md: 14,
  lg: 16
) !default;

// body に設定する letter-spacing（単位は自由）
$body-letter-spacing: (
  sm: 0,
  md: 0,
  lg: 0
) !default;

// ベースのline-height
$base-line-height: (
  sm: 1.4,
  md: 1.6,
  lg: 1.6
) !default;
