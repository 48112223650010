@charset 'UTF-8';

// footer
// ------

footer {
  background: transparent;
  flex: none;
  padding-top: map-unit( $footer-padding-top, sm );
  width: 100%;
  z-index: map-unit( $z-index, footer );
  @include media(md) {
    padding-top: map-unit( $footer-padding-top, md );
  }
  @include media(lg) {
    padding-top: map-unit( $footer-padding-top, lg );
  }
}

.footer-wrap {
  background: $background-footer;
  position: relative;
  padding: 0 map-unit( $footer-container-padding, sm );
  @include media(md) {
    padding: 0 map-unit( $footer-container-padding, md );
  }
  @include media(lg) {
    padding: 0 map-unit( $footer-container-padding, lg );
  }
  @include widthWrapper( $footer-container-width, $footer-container-padding );
}

.footer-container {
  margin: 0 auto;
  @include widthContainer( $footer-container-width );
}

.footer-upper {
  display: flex;
  flex-flow: row wrap;
  padding: .8rem 0;
  width: 100%;
  li {
    flex: 0 0 50%;
    text-align: center;
    a {
      color: #fff;
    }
    @include media( md ) {
      flex: 1;
    }
  }
}

.footer-lower {
  border-top: 1px solid darken( $base-color, 10% );
  color: #fff;
  line-height: 1.8;
  padding: .8em 0;
  text-align: center;
  @include media( md ) {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  .logo {
    display: block;
    img {
      width: 120px;
      height: auto;
    }
  }
  .address {
    font-size: .875em;
    @include media( md ) {
      margin-right: 1em;
    }
  }
  .tel {
    font-size: 1.4em;
  }
}
