@charset 'UTF-8';

// TOP PAGE
// --------

// Slider
.slider-wrapper {
  background: transparent;
  position: relative;
  width: 100%;
  height: auto;
  .slider-in-heading {
    color: $white-color;
    font-size: 1em;
    text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 .2em black;
    position: absolute;
    bottom: 5%;
    left: 5%;
    z-index: 9;
    @include media(lg){
      font-size: 1.5em;
    }
  }
}


// お知らせ UL
.top-info {
  background: #fff;
  border: 1px solid #e8e6d9;
  border-radius: 5px;
  width: 100%;
  font-size: .875em;

  li {
    &:nth-of-type(1) {
      background: #e8e6d9;
      padding: 1rem;
    }
    &:nth-child(odd):nth-child(n + 3) {
      background: #FAF9F3;
    }
    &.no-link {
      display: block;
      padding: 2rem 3.5rem 2rem 1.5rem;
    }

    dl {
      dt {
        color: #333;
        margin-bottom: .6em;
        span {
          color: #fff;
          display: inline-block;
          font-size: 76.5%;
          font-weight: normal;
          margin-left: .5em;
          padding: .125em .4em;
          &.news {
            background: $base-color;
          }
          &.topics {
            background: $second-color;
          }
        }
      }
      dd {
        color: #333;
        transition: color .2s;
      }
    }

    a {
      display: block;
      padding: 2rem 3.5rem 2rem 1.5rem;
      position: relative;
      transition: color .3s;
      &:hover {
        text-decoration: none;
        dd {
          color: lighten( #333, 20% );
        }
      }

      &::after {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        border-right: 2px solid #e9546b;
        border-top: 2px solid #e9546b;
        transform: rotate(45deg);
        position: absolute;
        top: 42%;
        right: 2rem;
      }
    }
  }
}


// 告知エリア
.bulletin {
  background: #fff;
  border: 1px solid #e8e6d9;
  border-radius: 5px;
  width: 100%;
  transition: all .3s ease-out;

  &-header {
    background: $base-color;
    border-radius: 5px 5px 0 0;
    color: #fff;
    font-size: 1em;
    text-align: center;
    padding: .3em 0;
  }

  &-body {
    color: #333;
    padding: 1em;
  }

  a {
    text-decoration: none;
  }
  &:hover {
    box-shadow: 0px 0px 3px 2px #e1e1e1;
  }
}



// medical procedures
//
.medical-procedures {
  background: $white-color;
  caption {
    background: $base-color;
    border-radius: 6px 6px 0 0;
    color: $white-color;
    font-size: .85em;
    font-weight: bold;
    padding: .4em 0;
  }
  th {
    font-size: .8em;
  }
  td {
    color: $gray-600;
    text-align: center;
    i {
      color: $fourth-color;
    }
  }
}




// banner area
//
.banner-area {
  background: transparent;
  margin-top: 2rem;
  overflow: hidden;
  //padding: 1rem;
  position: relative;
  width: 100%;
  //height: 74px; // バナーの高さ + 20px
}

.carousel-banner {
  margin: 0 auto;
  width: 90%;
}




// SUB PAGE
// --------

// メインコンテンツを2カラムにする
.main_contents_column_wrapper {
  display: flex;
  flex-flow: column wrap;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  @include media( md ){
    flex-flow: row wrap;
  }

  .main_column {
    margin-bottom: 3.6rem;
    @include media( md ){
      flex: 1;
      width: auto;
    }
  }

  .side_column {
    order: 1;
    width: 100%;
    @include media( md ){
      margin-right: 3.6rem;
      order: 0;
      width: 17.8rem;
    }
    @include media( lg ){
      margin-right: 4rem;
      width: 24.4rem;
    }
  }
}



// side column list
.side_list_group {
  background: transparent;
  li {
    border-bottom: 1px dashed $gray-300;
    display: block;
    position: relative;
    &.title {
      font-weight: bold;
      padding: .25em 0em;
    }
    a {
      color: $base-color;
      display: block;
      padding: .5em 0 .5em 1em;
      transition: background $transition, color $transition;
      &:hover {
        background: lighten($base-color, 50%);
      }
    }
  }
}



// google map
.google-map {
  padding-top: map-unit($section-gutter, sm);
  width: 100%;
  height: 32rem;
  @include media(lg){
    padding-top: map-unit($section-gutter, lg);
  }
}



// ページ内リンク
//
.page-anchor {
  color: $base-color;
  display: inline-block;
  font-size: 1.2em;
  transition: color $transition;
  &:before {
    content: '\f078';
    display: inline-block;
    font-family: $icon-font-family;
    font-weight: 900;
    margin-right: .4em;
    width: 1.28571429em;
    text-align: center;
  }
  &:hover {
    color: lighten( $base-color, 10% );
  }
  @include media(lg){
    font-size: 1em;
  }
}



// サイトマップ
.sitemap {
  & > li {
    margin-bottom: 1.2em;
    position: relative;

    a {
      color: $body-text-color;
      &:before {
        color: inherit;
        content: '\f054';
        display: inline-block;
        font-family: $icon-font-family;
        font-weight: 900;
        width: 1.28571429em;
        text-align: center;
      }
    }

    span {
      background: transparent;
      color: $gray-700;
      display: block;
      font-weight: bold;
      margin-bottom: 1em;
      //padding: .4em 0 .4em 1em;
      @include media(lg){
        font-size: .85em;
      }
    }

    ul {
      margin-left: .8em;
      li {
        margin-bottom: 1em;

      }
    }
  }
}
