@charset 'UTF-8';

// icon
// ----

.icon {
  span {
    &::after {
      @include icon-default;
      margin-left: .4em;
    }
    &.left {
      &::after { content: none; }
      &::before {
        @include icon-default;
        margin-right: .4em;
      }
    }
    @each $name, $icon in $icon-type {
      &.#{$name} {
        &::after {
          content: $icon !important;
        }
        &.left  {
          &::after {
            content: none !important;
          }
          &::before {
            content: $icon !important;
          }
        }
      }
    }
  }
  // color pattern
  @each $name, $color in $color-pattern {
    & span.ic-#{$name} {
      &::after, &::before {
        color: $color;
      }
    }
  }
}
