@charset 'UTF-8';

// header
// ------

header {
  background: transparent;
  height: map-unit( $height-header, sm, rem );
  padding-bottom: map-unit( $header-padding-bottom, sm );
  width: 100%;
  z-index: map-unit($z-index, header);
  @include media(md) {
    height: map-unit( $height-header, md, rem );
    padding-bottom: map-unit( $header-padding-bottom, md );
  }
  @include media(lg) {
    height: map-unit( $height-header, lg, rem );
    padding-bottom: map-unit( $header-padding-bottom, lg );
  }
}

.header-wrap {
  background: $background-header;
  position: fixed;
  padding: 0 map-unit( $header-container-padding, sm );
  width: 100%;
  z-index: map-unit($z-index, header);
  @include media(md) {

    position: relative;
    padding: 0 map-unit( $header-container-padding, md );
  }
  @include media(lg) {
    padding: 0 map-unit( $header-container-padding, lg );
  }
  @include widthWrapper( $header-container-width, $header-container-padding );
}

// TOP と SUB で background-color を変える
.home .header-wrap {
  @include media(md) {
    background: transparent;
  }
}



.header-container {
  margin: 0 auto;
  @include widthContainer( $header-container-width );
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: map-unit( $height-header, sm, rem );
  @include media(md) {
    height: map-unit( $height-header, md, rem );
  }
  @include media(lg) {
    height: map-unit( $height-header, lg, rem );
  }

  .header-inner-left {

    .header-title {
      img {
        width: 200px;
        height: auto;
      }
    }
  }

  .header-inner-right {
    display: flex;
    align-items: center;
    a {
      color: #fff;
      display: block;
      font-weight: bold;
      margin-left: 2em;
    }
  }
}


.header_tel_number {
  display: none;
  @include media( md ) {
    display: block;
    img {
      width: 226px;
      height: auto;
    }
  }
}
