@charset 'UTF-8';

// Pagination
// ----------
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  a {
    border: 1px solid $gray-500;
    border-radius: 3px;
    color: #333;
    display: block;
    min-width: 32px;
    padding: .4rem .8rem;
    transition: border $transition;
    &:hover {
      border: 1px solid $gray-300;
      }
  }
  .current {
    background: $base-color;
    border: 1px solid $base-color;
    border-radius: 3px;
    color: #fff;
    display: block;
    min-width: 32px;
    padding: .4rem .8rem;
  }

  span {
    color: $gray-600;
    display: block;
    margin: 0 4px;
  }

  li {
    margin: 0 2px;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
  }
}
