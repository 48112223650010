@charset 'UTF-8';

// common-wrapper, common-column
// ------
.common-wrapper {
  position: relative;
  width: 100%;
}

.common-column {
  display: flex;
  flex-flow: column nowrap;
  align-content: space-between;
  min-height: 100vh;
  header, footer {
    flex: none;
  }
  main {
    width: 100%;
    flex: 1 0 auto;
    //margin-top: map-unit( $height-header, sm, rem );
    min-height: 0%; // IE11用
    @include media(md) {
      margin-top: 0;
    }
  }
}


// Container
// ---------
.container-wrapper {
  background: $background-container-wrapper;
  padding: 0 map-unit( $container-padding, sm );
  @include media(md) {
    padding: 0 map-unit( $container-padding, md );
  }
  @include media(lg) {
    padding: 0 map-unit( $container-padding, lg );
  }
  @include widthWrapper( $container-width, $container-padding );
}

.container {
  background: $background-container;
  margin: 0 auto;
  @include widthContainer( $container-width );
}
// IE11 hack
// ---------
_:-ms-lang(x), .container { flex: 0; }


// section-inner
// -------------
.section-inner {
  padding-top: map-unit($section-gutter, sm);
  padding-bottom: map-unit($section-gutter, sm);
  @include media(md) {
    padding-top: map-unit($section-gutter, md);
    padding-bottom: map-unit($section-gutter, md);
  }
  @include media(lg) {
    padding-top: map-unit($section-gutter, lg);
    padding-bottom: map-unit($section-gutter, lg);
  }
}

.section-inner-upper {
  padding-bottom: map-unit($section-gutter, sm);
  @include media(md) {
    padding-bottom: map-unit($section-gutter, md);
  }
  @include media(lg) {
    padding-bottom: map-unit($section-gutter, lg);
  }
}

.section-inner-lower {
  padding-top: map-unit($section-gutter, sm);
  @include media(md) {
    padding-top: map-unit($section-gutter, md);
  }
  @include media(lg) {
    padding-top: map-unit($section-gutter, lg);
  }
}


// Element
// -------
.element {
  padding-top: map-unit( $element-gutter, sm );
  @include media(md) {
    padding-top: map-unit( $element-gutter, md );
  }
  @include media(lg) {
    padding-top: map-unit( $element-gutter, lg );
  }
}
