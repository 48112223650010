@charset 'UTF-8';

// alert
// -----

// alert の border の太さ
$alert-border: 1px;

// alert の border-radius
$alert-border-radius: 0px;

// alert の box-shadow
$alert-box-shadow: 0 2px 4px 0 rgba( 0,0,0, .12 );

// color
// -----
// background-color, color
$alert-color: (
  'base'   : ( 'bg' : $base-color,   'color' : #fff ),
  'first'  : ( 'bg' : $first-color,  'color' : #fff ),
  'second' : ( 'bg' : $second-color, 'color' : #fff ),
  'third'  : ( 'bg' : $third-color,  'color' : #fff ),
  'fourth' : ( 'bg' : $fourth-color, 'color' : #fff ),
  'five'   : ( 'bg' : $five-color,   'color' : #fff )
) !default;

// outline
// background-color, color, border-color
$alert-outline-color: (
  'base'   : ( 'bg' : #fff, 'color' : $base-color,   'border' : $base-color ),
  'first'  : ( 'bg' : #fff, 'color' : $first-color,  'border' : $first-color ),
  'second' : ( 'bg' : #fff, 'color' : $second-color, 'border' : $second-color ),
  'third'  : ( 'bg' : #fff, 'color' : $third-color,  'border' : $third-color ),
  'fourth' : ( 'bg' : #fff, 'color' : $fourth-color, 'border' : $fourth-color ),
  'five'   : ( 'bg' : #fff, 'color' : $five-color,   'border' : $five-color )
) !default;
