@charset 'UTF-8';

// typography
// ----------

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: .4em;
  line-height: 1.4;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
  &.second {
    background: lighten($base-color, 45%);
    box-shadow: 0px 0px 0px 5px lighten($base-color, 45%);
    border: dashed 2px white;
    color: $base-color;
    margin-bottom: 1em;
    padding: .2em .5em;
  }
}

h3 {
  font-size: $h3-font-size;
  &.second {
    border-bottom: solid 3px lighten($base-color, 45%);
    color: darken($base-color, 10%);
    font-size: $h3-font-size * 0.8;
    margin-bottom: 1em;
    padding-bottom: .3em;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      display: block;
      border-bottom: solid 3px $base-color;
      bottom: -3px;
      width: 20%;
    }
  }
}

h4 {
  font-size: $h4-font-size * .8;
  &.first {
    background: $white-color;
    border-left: 2px solid $second-color;
    color: $gray-700;
    margin-bottom: 1em;
    padding: .5em 1em;
  }
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}


p {
  font-size: inherit;
  letter-spacing: map-unit( $paragraph-letter-spacing, sm );
  line-height: map-unit( $paragraph-line-height, sm );
  //margin-bottom: map-unit( $paragraph-margin, sm );
  text-rendering: optimizeLegibility;
  @include media(md) {
    letter-spacing: map-unit( $paragraph-letter-spacing, md );
    line-height: map-unit( $paragraph-line-height, md );
    //margin-bottom: map-unit( $paragraph-margin, md );
  }
  @include media(lg) {
    letter-spacing: map-unit( $paragraph-letter-spacing, lg );
    line-height: map-unit( $paragraph-line-height, lg );
    //margin-bottom: map-unit( $paragraph-margin, lg );
  }
}

// 主に paragraph を段落として使用しない場合に margin-bottom を打ち消す
.phrase {
  margin-bottom: 0 !important;
}

.asterisk {
  font-family: -apple-system  BlinkMacSystemFont, 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, sans-serif;
}

// イタリック
// -------
i {
  font-style: italic;
  line-height: inherit;
}

// ボールド
// ------
strong,
em,
b {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}

// code
// ----
pre {
  background: #f2f2f2;
  margin: 1em 0;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
}

code {
  background: transparent;
  color: #545454;
  font-family: 'Source Code Pro', monospace;
  font-size: .9375em;
  font-weight: bold;
  vertical-align: initial;
}
