@charset 'UTF-8';

// Breadlist
// ---------
.breadlist-wrapper {
  background: $background-breadlist;
  //border-top: 1px solid #ddd;
  //border-bottom: 1px solid #ddd;
  margin-bottom: 2em;
  padding: 1em map-unit( $breadlist-container-padding, sm );
  @include media(md) {
    padding: 1em map-unit( $breadlist-container-padding, md );
  }
  @include media(lg) {
    padding: 1em map-unit( $breadlist-container-padding, lg );
  }
  @include widthWrapper( $breadlist-container-width, $breadlist-container-padding );
}

.breadlist-container {
  margin: 0 auto;
  @include widthContainer( $breadlist-container-width );
}

.breadlist {
  display: flex;
  align-items: center;
  font-size: .85em;

  li {
    color: inherit;
    line-height: 1.1;
    //overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    & + li::before {
      content: '＞';
      color: $gray-500;
      padding-right: .5em;
      padding-left: .5em;
    }

    a {
      color: $body-text-color;
      &::active,
      &::visited {
        color: inherit;
      }
    }

    &.current {
      color: $gray-600;
      font-weight: bold;
    }
  }
}
