@charset 'UTF-8';

// footer
// ------

// .footer-container の横幅 .container と合わせたい場合は $container-width を使用する
$footer-container-width: (
  'sm': map-get( $container-width, sm ),
  'md': map-get( $container-width, md ),
  'lg': map-get( $container-width, lg )
) !default;

// .footer-container の左右の padding .container と合わせたい場合は $container-padding を使用する
$footer-container-padding: (
  'sm': map-get( $container-padding, sm ),
  'md': map-get( $container-padding, md ),
  'lg': map-get( $container-padding, lg )
) !default;

// <main> と <footer> の間隔
$footer-padding-top: (
  sm: 4em,
  md: 4em,
  lg: 4em
) !default;

$background-footer: $base-color !default; // footer の background-color
