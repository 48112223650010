@charset 'UTF-8';

// flex
// ----
.flex-row {
  display: flex;
  flex-flow: row wrap;
}

.flex-column {
  display: flex;
  flex-flow: column wrap;
}
