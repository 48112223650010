@charset 'UTF-8';

// Block
// -----

.block {
  background: $white-color;
  border: $block-border solid $base-color;
  border-radius: $block-border-radius;
  box-shadow: $block-box-shadow;
  overflow: hidden;

  &.height {
    height: 100%;
  }

  // border color pattern theme color
  @each $name, $value in $block-color {
    &.#{$name} {
      border: $block-border solid map-get( $value, 'bg' );
    }
  }
  // border color pattern gray & common color
  @each $name, $value in $basic-colors {
    &.#{$name} {
      border: $block-border solid $value;
    }
  }
}

.block-header {
  background: multi-array( $block-color, 'base', 'bg' );
  color: multi-array( $block-color, 'base', 'color' );
  font-size: inherit;
  font-weight: bold;
  padding: .3em 1em calc( .3em + #{$block-border} );

  // bacground color pattern theme color
  @each $name, $value in $block-color {
    .#{$name} & {
      background: map-get( $value, 'bg' );
    }
  }
  // bacground color pattern gray & common color
  @each $name, $value in $basic-colors {
    .#{$name} & {
      background: $value;
    }
  }
}

.block-body {
  line-height: map-unit( $paragraph-line-height, sm );
  padding: 1em;
  @include media(md) {
    line-height: map-unit( $paragraph-line-height, md );
  }
  @include media(lg) {
    line-height: map-unit( $paragraph-line-height, lg );
  }
}
