@charset 'UTF-8';

// Icon
// -----------------------------------------------------
$icon-font-family: 'Font Awesome 5 Free';

// 種類を追加したい場合：'使用する名前': Fontawesomeのコード;
// Fontawesome のアイコンが Solid の場合 font-weight: 900; 、Regular の場合 font-weight: 400;
$icon-type: (
  'arrows':  '\f138',
  'pdf':     '\f1c1',
  'window':  '\f2d2',
  'tel':     '\f098',
  'mail':    '\f0e0',
  'fax':     '\f1ac',
  'privacy': '\f13e',
  'link':    '\f0c1'
) !default;
