@charset 'UTF-8';

// Horizontal Line
// ---------------

// ボーダーの種類
$hr-border: 1px solid #e5e5e5 !default;

// ボーダー上下の間隔
$hr-margin: (
  'sm' : ( 'top' : 1em, 'bottom' : 1em ),
  'md' : ( 'top' : 1em, 'bottom' : 1em ),
  'lg' : ( 'top' : 1em, 'bottom' : 1em )
) !default;
