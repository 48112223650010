@charset 'UTF-8';

// Accordion
// ---------

.accordion {
  dt {
    background: $base-color;
    color: #fff;
    cursor: pointer;
    font-weight: normal;
    margin: 0;
    padding: 1rem 0 1rem 1.2rem;
    position: relative;
    &:not(:first-child) {
      border-top: 1px solid #fff;
      margin: 0;
    }
    &:after {
      font-family: $icon-font-family;
      content: '\f067';
      font-weight: 900;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 1.6rem;
      transform: translateY(-50%);
      transition: transform $transition;
    }

    &.open:after {
      transform: translateY(-50%) rotate(45deg);
    }
  }

  dd {
    display: none;
    padding: 1em;
  }
}
