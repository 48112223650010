@charset 'UTF-8';

// 隣接する要素の余白を設定
// 隣接したときに margin-top を設定する
// ------------------------------



// 段落の余白（単位：em）
$paragraph-margin: (
  sm: 1em,
  md: 1em,
  lg: 1em
) !default;



// image の余白（単位：em）
$image-margin: (
  sm: 2em,
  md: 2em,
  lg: 2em
) !default;



// button の余白（単位：em）
$button-margin: (
  sm: 1em,
  md: 1em,
  lg: 1em
) !default;



// alert の余白（単位：em）
$alert-margin: (
  sm: 1em,
  md: 1em,
  lg: 1em
) !default;



// block の余白（単位：em）
$block-margin: (
  sm: 1em,
  md: 1em,
  lg: 1em
) !default;



// table の余白（単位：em）
$table-margin: (
  sm: 1em,
  md: 1em,
  lg: 1em
) !default;
