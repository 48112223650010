@charset 'UTF-8';

// フォントサイズ
$label-font-size: .875em;

// ラベルの中 上下の padding
$label-padding-tb: (
  sm: .425em,
  md: .425em,
  lg: .425em
  ) !default;

// ラベルの中 左右の padding
$label-padding-lr: (
  sm: .6em,
  md: .6em,
  lg: .6em
  ) !default;

$label-border-radius: 0px !default; // 角丸
$label-border: 1px !default;        // アウトライン時のボーダーの太さ

// Color
// -----
$label-color: (
  'base'   : ( 'bg' : $base-color,   'color' : #fff ),
  'first'  : ( 'bg' : $first-color,  'color' : #fff ),
  'second' : ( 'bg' : $second-color, 'color' : #fff ),
  'third'  : ( 'bg' : $third-color,  'color' : #fff ),
  'fourth' : ( 'bg' : $fourth-color, 'color' : #fff ),
  'five'   : ( 'bg' : $five-color,   'color' : #fff )
) !default;

// outline
$label-outline-color: (
  'base'   : ( 'bg' : #fff, 'color' : $base-color,   'border' : $base-color ),
  'first'  : ( 'bg' : #fff, 'color' : $first-color,  'border' : $first-color ),
  'second' : ( 'bg' : #fff, 'color' : $second-color, 'border' : $second-color ),
  'third'  : ( 'bg' : #fff, 'color' : $third-color,  'border' : $third-color ),
  'fourth' : ( 'bg' : #fff, 'color' : $fourth-color, 'border' : $fourth-color ),
  'five'   : ( 'bg' : #fff, 'color' : $five-color,   'border' : $five-color )
) !default;
