@charset 'UTF-8';

// 変数
// ---

$burger-border-color: #fff; // burger のカラー
$burger-border-thickness: 2px; // burger の太さ
// burger の長さ
$burger-border-width: (
  'sm': 50px,
  'md': 32px,
  'lg': 36px
);
// burger 同士の間隔
$burger-border-margin: (
  'sm': 6px,
  'md': 8px,
  'lg': 10px
);

$burger-open-class: '.open-menu'; // burger open 時に付与するクラス名


// Burger
// ------
.burger-icon {
  background: $base-color;
  cursor: pointer;
  display: block;
  width: map-get( $burger-border-width, 'sm' );
  height: map-get( $burger-border-width, 'sm' );
  position: relative;
  span {
    background: $burger-border-color;
    display: block;
    position: absolute;
    left: 12px;
    width: 26px;
    height: $burger-border-thickness; // 太さ
    transition: all .4s;
    &:nth-of-type(1) {
      top: 12px;
    }
    &:nth-of-type(2) {
      top: 18px;
    }
    &:nth-of-type(3) {
      top: 24px;
    }
  }
  div {
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0;
    margin-top: 30px;
  }

  @include media(md) {
    display: none;
  }
}

// OPEN
#{$burger-open-class} .burger-icon {
  //background: transparent;
  span {
    &:nth-of-type(1) {
      transform: translateY(6px) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(-6px) rotate(45deg);
    }
  }
}
