@charset 'UTF-8';

.tab-label {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #ddd;
  & > li {
    color: #07d;
    position: relative;
    transition: $transition;
    &:nth-child(n+2) {
      margin-left: .5em;
    }
    a {
      background: $gray-200;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border-bottom: 4px solid $gray-200;
      display: block;
      padding: .8rem 1.2rem;
      transition: background $transition;
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
      &:not(.active):hover {
        background: #f5f5f5;
        border-bottom: 4px solid $base-color;
        color: #333;
        outline: none;
      }
    }
    &.current a {
      border-bottom: 4px solid $base-color;
      background: #fff;
      color: #333;
    }
  }
}

.tab-content {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 1rem;
}
