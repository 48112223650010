@charset 'UTF-8';

// Breadlist Variables
// --------------------

// 項目同士の余白
$field-margin-bottom: (
  'sm': 2em,
  'md': 2em,
  'lg': 2em
) !default;
