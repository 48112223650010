@charset 'UTF-8';

// wrapper
// -------
.global-navigation {
  background: $background-header;
  position: relative;
  @include media(md) {
    margin-bottom: 1em;
  }
}

// navigation
// ----------

// Variables
$global-width: 270px; // ドロワーメニューの横幅
$global-height: 9.4rem; // PC時のメニューの高さ


.global-menu {
  background: #f4f4f4;
  position: fixed;
  top: map-unit( $height-header, sm, rem );
  right: ( - $global-width );
  width: $global-width;
  height: 100%;
  overflow-y: auto;
  //transform: translateX( $global-width );
  transition: right .3s;
  z-index: 5;
  @include media(md) {
    top: map-unit( $height-header, md, rem );
  }
  li {
    border-bottom: 1px solid #d6d6d6;
    position: relative;
    a {
      color: $body-text-color;
      display: block;
      font-size: 1.5rem;
      padding: 1em 1em 1em 2em;
      position: relative;
    }
  }
  //
  @include media( md ) {
    background: transparent;
    display: flex;
    overflow: visible;
    position: static;
    //top: 0;
    //right: 0;
    width: 100%;
    height: auto;
    li {
      border-bottom: none;
      cursor: pointer;
      flex: 1;
      position: relative;
      text-align: center;
      &:not(:last-child) a::before {
        content: '';
        background-image: url(../../images/common/nav_border.png);
        background-repeat: no-repeat;
        background-size: 3px 18px;
        position: absolute;
        right: -0px;
        top: 0;
        bottom: 0;
        width: 3px;
        height: 18px;
        margin: auto;
      }
      a {
        background: transparent;
        color: $base-color;
        font-size: 1.5rem;
        font-weight: bold;
        padding: 1rem;
        span::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          width: 90%;
          height: 1px;
          margin: auto;
          background-color: $second-color;
          transform: scaleX(0);
          transition: transform $transition, color $transition;
          z-index: 999;
        }
        &:hover {
          color: lighten( $base-color, 10% );
          span::before {
            transform: scaleX(1);
          }
        }
      }
    }
  }
  @include media( lg ) {
    li {
      a {
        font-size: 1.6rem;
      }
    }
  }
}

// TOP と SUB で background-color を変える
.home .global-navigation {
  @include media(md) {
    background: transparent;
  }
}


// オーバーレイ
// ---------
.drawer-menu-overlay {
  background: rgba(0,0,0,0.75);
  display: block;
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: visibility $transition linear, opacity $transition linear;
}



// ********** ********** **********
// toggle open class

// menu
#{$burger-open-class} .global-menu {
  right: 0;
}

// オーバーレイの表示
#{$burger-open-class} .drawer-menu-overlay {
  opacity: 1;
  visibility: visible;
}
