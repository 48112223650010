@charset 'UTF-8';

// 各項目の間隔
// ---------
.form-field {
  margin-bottom: map-get( $field-margin-bottom, sm );
  @include media(md) {
    margin-bottom: map-get( $field-margin-bottom, md );
  }
  @include media(lg) {
    margin-bottom: map-get( $field-margin-bottom, lg );
  }
}

label {
  display: block;
  &.item {
    color: $gray-700;
    font-weight: bold;
    margin-bottom: .5em;
    .required {
      color: $red-color;
    }
  }
  // jquery.validate.min.js エラー表示用
  &.error {
    color: $red-color;
    display: inline-block;
    font-weight: bold;
    margin-left: .6em;
  }
}


// 外枠
// ---------
input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='reset']):not([type='button']),
textarea {
  background: $white-color;
  border: 1px solid #ccc;
  box-shadow: none;
  color: #333;
  //display: block;
  font-size: 1em;
  height: 3em;
  padding: 0 1em;
  line-height: 1.42857143;
  width: 100%;
  transition: border-color $transition, background-color $transition;

  &:focus {
    background: $white-color;
    border-color: $base-color;
    box-shadow: 0 0 6px 1px rgba($base-color, .075);
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }
  &::-moz-placeholder {
    color: #999;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: #999;
  }
  &::-webkit-input-placeholder {
    color: #999;
  }
  &::-ms-expand {
    background-color: #fff;
    border: 0;
  }
}

textarea {
  height: auto;
  padding: 1em;
}

// SELECT
// ------
select {
  width: 100%;
  &::-moz-focus-inner {
    border: 0;
  }
}

.select {
  display: inline-block;
  position: relative;
  & select {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: inherit;
    cursor: pointer;
    display: block;
    font-size: 1em;
    height: 3em;
    line-height: 1.42857143;
    padding: 0 2.5em 0 1em;
    position: relative;
    outline: none;
    overflow: hidden;
  }

  &:after { // 矢印
    border: 1px solid #999;
    border-right: 0;
    border-top: 0;
    content: '';
    display: block;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 1em;
    transform: rotate(-45deg) translateY(-50%);
    width: 9px;
    height: 9px;

  }
}

// Radio Button & Check Box
// ------------------------
.checkbox-group,
.radio-group {
  display: inline-block;

  &.row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    .label-checkbox + .label-checkbox,
    .label-radio + .label-radio {
      margin-left: 1em;
    }
  }
}

.label-checkbox input[type='checkbox'],
.label-radio input[type='radio'] {
  cursor: pointer;
}

.label-checkbox,
.label-radio {
  cursor: pointer;
  display: flex;
  align-items: center;
  .lever {
    padding-left: .4em;
  }
}

// Submit Button
// -------------
input[type='submit'] {
  @extend .button;
}
